<template>
    <div class="view pa24">
        <div class="d-flex">
            <div>
                <el-input class="w200 mb10 mr10" placeholder="请输入用户名称" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
                <!-- <el-button type="primary" @click="searchFun">导出数据</el-button> -->
            </div>
        </div>
        <el-tabs v-model="accountType" @tab-click="searchFun">
            <el-tab-pane label="名片端" name="1"></el-tab-pane>
            <el-tab-pane label="园区端" name="8"></el-tab-pane>
            <el-tab-pane label="社团端" name="9"></el-tab-pane>
        </el-tabs>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="userName" align="center" width="150" label="用户名称" show-overflow-tooltip />
                <el-table-column prop="phone" align="center" label="手机" show-overflow-tooltip />
                <el-table-column prop="balance" align="center" width="120" label="金额(元)" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div>
                            <div style="color: #67c23a" v-if="scope.row.payType == 1">
                                +{{ scope.row.money.toFixed(2) }}
                            </div>
                            <div style="color: #f56c6c" v-if="scope.row.payType == 0">
                                -{{ scope.row.money.toFixed(2)  }}
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="name" align="center" width="80" label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.payType === 1">{{ scope.row.payTypeName }}</el-tag>
                        <el-tag v-if="scope.row.payType === 0" type="danger">{{ scope.row.payTypeName }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="remark" align="center" label="操作描述" show-overflow-tooltip />
                <el-table-column prop="updateTime" align="center" width="200" label="操作时间" show-overflow-tooltip />
            </template>
        </commonTable>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getUserWalletRecordList } from "@/api/walletRecords";
export default {
    name: "sendCardsTable",
    components: {
        commonTable,
    },
    data() {
        return {
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [{ name: "测试" }],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            accountType: "1"
        };
    },
    filters: {

    },
    created() {
        this.getList();
    },
    methods: {
        /**@method 搜索 */
        searchFun() {
            this.currentPage = 1;
            this.getList();
        },
        addUpdate() {
            //修改添加会员等级
            this.$refs.formData.validate((valid) => {
                if (valid) {

                }
            });
        },
        /**@method 获取列表 */
        async getList(payload) {
            let params = {
                accountType: this.accountType,
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                userName: this.searchData.name
            };
            try {
                this.loading = true;
                let result = await getUserWalletRecordList(params);
                this.loading = false;

                const { data } = result;
                let tableData = [];
                let payType = {
                    0: "消费",
                    1: "充值"
                }
                for (let row of data.pageInfo.list) {
                    row.payTypeName = payType[row.payType]
                    tableData.push(row);
                }
                this.tableData = tableData;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>